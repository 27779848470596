const Footer = () => {
  return (
    <footer className="text-gray-500 p-3 text-sm  items-center justify-center space-x-4 text-center mt-16">
      
      
      <a
        href="https://X-Trategy.com/vote"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-[#F95923] transition-all"
      >
        Notice of Annual Meeting of Shareholders
      </a>
      {/* <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-[#F95923] transition-all"
      >
        Terms & Conditions
      </a> */}

<p className="mt-2">X-Trategy Copyright © 2024-2025 All rights reserved.</p>
    </footer>
  );
};

export default Footer;
