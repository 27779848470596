import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Home from "../views/Home";
import End from "../views/End";
import Admin from "../views/Admin";
import Adminvote from "../views/Adminvote";
import AdminCheckVote from "../views/AdminCheckVote";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/end" element={<End />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/adminvote" element={<Adminvote />} />
        <Route exact path="/admincheckvote" element={<AdminCheckVote />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
