import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

// import 'bootstrap/dist/css/bootstrap.css';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Container from 'react-bootstrap/Container';
// import Table from 'react-bootstrap/Table';
// import Form from 'react-bootstrap/Form';
const Preguntas = [
  {
    id: 1,
    titulo: "",
    pregunta: "John Smith",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 2,
    titulo: "",
    pregunta: "Angela Davis",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 3,
    titulo: "",
    pregunta: "Michael Johnson",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 4,
    titulo: "",
    pregunta: "Emily Brown",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 5,
    titulo: "",
    pregunta: "Robert Miller",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 6,
    titulo: "PROPOSAL NO. 2",
    pregunta:
      "Proposal to approve executive compensation packages, ensuring our ability to attract and retain top talent while aligning executive incentives with the long-term success of the company.",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 7,
    titulo: "PROPOSAL NO. 3",
    pregunta:
      "Proposal to approve changes to the company's bylaws, ensuring they remain relevant, up-to-date and in line with best practices for corporate governance.",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 8,
    titulo: "PROPOSAL NO. 4",
    pregunta: "Proposal to approve the appointment of the external auditor, ensuring the accuracy and reliability of the company's financial reports.",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 9,
    titulo: "PROPOSAL NO. 5",
    pregunta:
      "Proposal to approve investment in research and development, supporting the company's commitment to innovation and long-term growth.",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
  {
    id: 10,
    titulo: "PROPOSAL NO. 6",
    pregunta:
      "Proposal to approve the strategic plan for the next five years, outlining our vision, strategic objectives, and key initiatives for future growth and success.",
    respuestas: [
      { id: "for", title: "For" },
      { id: "against", title: "Against" },
      { id: "abstain", title: "Abstain" },
    ],
  },
];

const firstProposal1 = [
  {
    id: 1,
    title: "For All",
    description: "For all the nominees.",
    value: "forallnom",
  },
  {
    id: 2,
    title: "Withhold",
    description: "Withhold authority for the nominees.",
    value: "withhol",
  },
  {
    id: 3,
    title: "For All Except",
    description: "For all except selected nominees.",
    value: "forallexcp",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const people = [
  { id: 1, name: "John Smith", value: false },
  { id: 2, name: "Angela Davis", value: false },
  { id: 3, name: "Michael Johnson", value: false },
  { id: 4, name: "Emily Brown", value: false },
  { id: 5, name: "Robert Miller", value: false },
];

const votes = [
  { id: "for", title: "For" },
  { id: "against", title: "Against" },
  { id: "abstain", title: "Abstain" },
];

const Home = () => {
  const [selectedfirstProposal1, setFirstProposal1] = useState(
    firstProposal1[0]
  );
  const [selectedfirstProposal2, setFirstProposal2] = useState(votes[0]);
  const [selectedfirstProposal3, setFirstProposal3] = useState(votes[0]);
  const [selectedfirstProposal4, setFirstProposal4] = useState(votes[0]);
  const [selectedfirstProposal5, setFirstProposal5] = useState(votes[0]);
  const [selectedfirstProposal6, setFirstProposal6] = useState(votes[0]);
  const [peopleList, setPeopleList] = useState(people);
  const [nameRecoverForm, SetNameRecoverForm] = useState("");
  const [actionsRecoverForm, SetActionsRecoverForm] = useState("");
  const [EmailRecoverForm, SetEmailRecoverForm] = useState("");

  const navigate = useNavigate();
  const functions = getFunctions();
  //linea para conectar el emulador
  //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [responses, setResponses] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showRecoverForm, setShowRecoverForm] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showButton, setShowButton] = useState(responses.length == 6);
  const [questionsData, setQuestionsData] = useState("");
  const [datacontrolnumber, setDataControlNumber] = useState("");
  const [disabledMembers, setDisableMembers] = useState(false);

  const checkCode = () => {
    setLoading(true);

    if (code.length !== 9) {
      setMessage("Invalid control number");
      setLoading(false);
    } else {
      console.log("code", functions);
      for (var ii = 1; ii <= 10; ii++) {
        responses[ii] = "free";
      }
      const controlNumber_validate = httpsCallable(
        functions,
        "controlNumber_validate"
      );
      controlNumber_validate({
        documentName: "YHFX64dEVo8b8Ep52eTA",
        controlNumber: code,
        responses: responses,
        isadm: true,
      }).then((result) => {
        if (result.data.status === 200) {
          setMessage("");
          setShowForm(true);
          //console.log(result);
          const questions_data = Object.values(result.data.questionsData);
          setQuestionsData(questions_data);
          const controlnumberdata = Object.values(
            result.data.controlNumberdata
          );
          setDataControlNumber(controlnumberdata);
          //console.log(controlnumberdata);
        } else {
          setShowTerms(false);
          setMessage(result.data.error);
          setCode("Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  const sendAnswers = () => {
    var temp = 0;
    
    setMessage("");

    responses.forEach((item) => {
      temp++;
    });
    if (temp != 10) {
      setMessage("Please complete all selections");
      return;
    }
    // setLoading(true);
    // console.log("code", code);
    // console.log("responses", responses);

    const controlNumber_validate = httpsCallable(
      functions,
      "controlNumber_vote"
    );
    controlNumber_validate({
      documentName: "YHFX64dEVo8b8Ep52eTA",
      controlNumber: code,
      responses: responses,
      isadm: true,
    }).then((result) => {
      if (result.data.status === 200) {
        setMessage("");
        navigate("/end");
      } else {
        setMessage(result.data.error);
      }
      setLoading(false);
    });
  };

  const update = (p, r) => {
    // console.log(p, r);
    responses[p] = r;
  };
  const validaterminos = () => {
    var checkbox = document.getElementById("terminos");
    var checked = checkbox.checked;

    setAcceptTerms(checked);

    if (checked) {
      document.getElementById("validacontrol").disabled = false;
    } else {
      document.getElementById("validacontrol").disabled = true;
    }
  };

  const forallnom = (opcion) => {
    // setDisableMembers(false);

    if (opcion == "forallnom") {
      setDisableMembers(true);
      for (var ii = 1; ii <= 5; ii++) {
        update(ii, "for");
      }

      let tempList = [...peopleList];
      tempList = tempList.map((x) => {
        x.value = true;
        return x;
      });
      console.log("🚀 ~ file: Home.js:301 ~ forallnom ~ tempList:", tempList)
      setPeopleList(tempList);
      
    } else if (opcion == "forallexcp") {
      setDisableMembers(false);

      for (var ii = 1; ii <= 5; ii++) {
        update(ii, "for");
      }

      let tempList = [...peopleList];
      tempList = tempList.map((x) => {
        x.value = false;
        return x;
      });
      setPeopleList(tempList);
    } else if (opcion == "withhol") {
      setDisableMembers(true);
      for (var ii = 1; ii <= 5; ii++) {
        update(ii, "against");
      }
      let tempList = [...peopleList];
      tempList = tempList.map((x) => {
        x.value = false;
        return x;
      });
      setPeopleList(tempList);
    }
  };
  const recoverCod = (valores) => {
    setLoading(true);
    
    if (valores.name.length < 1 || valores.actions<1) {
      setMessage("Invalid Name or number actions");
      setLoading(false);
      
    } else {
      
      const controlNumber_recover = httpsCallable(
        functions,
        "controlNumber_recover"
      );
      controlNumber_recover({
        documentName: "YHFX64dEVo8b8Ep52eTA",
        name: valores.name,
        actions: valores.actions,
      }).then((result) => {
        if (result.data.status === 200) {
          setMessage(result.data.data);
          
        } else {
          setShowTerms(false);
          setMessage(result.data.error);
          setCode("Something went wrong");
        }
        setLoading(false);
      });
    }
  };
  if (showRecoverForm) {
    return (
      <main>
        <div className="flex flex-col justify-start items-center mx-1">
          <div className="card w-100 flex flex-col items-center">
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={(event) => event.preventDefault()}
            >
              <div className="pt-8">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                    Recover your Control Number
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 text-center">
                    We are going to reset your control number
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        onChange={(value) =>
                          SetNameRecoverForm(value.target.value)
                        }
                        className="shadow-sm focus:ring-[#F95923] focus:border-[#F95923] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      N° Actions
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        onChange={(value) =>
                          SetActionsRecoverForm(value.target.value)
                        }
                        className="shadow-sm focus:ring-[#F95923] focus:border-[#F95923] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(value) =>
                          SetEmailRecoverForm(value.target.value)
                        }
                        autoComplete="email"
                        className="shadow-sm focus:ring-[#F95923] focus:border-[#F95923] block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <button
                    id="recoverCode"
                    className=" btn-secondary text-gray-800 rounded-full font-bold bg-gray-300 hover:bg-gray-400  block md:mx-auto w-full max-w-lg mt-8"
                    onClick={() => {
                      setShowRecoverForm(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    id="recoverCode"
                    className="btn-primary block md:mx-auto w-full max-w-lg mt-8"
                    onClick={() => {
                      const value = {
                        name: nameRecoverForm,
                        actions: actionsRecoverForm,
                        email: EmailRecoverForm,
                      };
                      console.log("VALORES ", value);
                      recoverCod(value);
                    }}
                  >
                    Recover
                  </button>
                  
                </div>
                {message && (
                      <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full max-w-lg rounded-xl">
                        Control Number: {message}
                      </p>
                      // <p className="mt-4 text-center bg-gradient-to-r from-[rgba(255,0,0,0.3)] to-transparent rounded-xl p-1 block w-full max-w-lg">{message}</p>
                    )}
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main>
      <div className="flex flex-col justify-start items-center mx-1">
        {loading ? (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        ) : (
          <div className="card w-100 flex flex-col items-center">
            {showForm === false ? (
              <>
                {showTerms === false ? (
                  <>
                    <h1 className="text-[#313131] font-bold text-xl text-center mb-4">
                      Provide a Control Number for Manual Vote
                    </h1>

                    {/* <form onSubmit={() => {console.log('Submit ')}}> */}
                    <div>
                      <VerificationInput
                        classNames={{
                          character:
                            "form-control h-8 md:h-full shadow-md shadow-[#e4e2f5] background-color[#f6f5fa]",
                          container: "w-auto",
                        }}
                        placeholder="&#160;&#160;"
                        validChars="0-9A-Z"
                        inputProps={{ type: "tel" }}
                        length={9}
                        onChange={(e) => setCode(e)}
                      />
                    </div>

                    {message && (
                      <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full max-w-lg rounded-xl">
                        {message}
                      </p>
                      // <p className="mt-4 text-center bg-gradient-to-r from-[rgba(255,0,0,0.3)] to-transparent rounded-xl p-1 block w-full max-w-lg">{message}</p>
                    )}

                    <button
                      // disabled={true}
                      id="validacontrol"
                      className="btn-primary block md:mx-auto w-full max-w-lg mt-8"
                      // type="submit"
                      onClick={() => {
                        setMessage("");
                        if (code.length < 9) {
                          setMessage("Please enter a valid control number");
                        } else {
                          setShowTerms(true);
                        }
                      }}
                    >
                      Continue
                    </button>

                    {/* </form> */}
                  </>
                ) : (
                  <>
                    <div>
                    <h1 className="text-[#313131] font-bold text-xl text-center mb-4">
                      This is a Manual Vote Process
                    </h1>
                    <div className="mx-auto max-w-2xl lg:text-center">
          
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Before submitting a vote on behalf of another person, please exercise caution and ensure that you have the proper authorization to do so. It is important to respect the rights and privacy of individuals when participating in voting processes.
          </p>
        </div>
                      <div className="justify-center flex mt-4">
                        <input
                          id="terminos"
                          name="terminos"
                          type="checkbox"
                          className="h-4 w-4 text-[#F95923] bg-gray-100 border-gray-300 rounded"
                          onClick={() => validaterminos()}
                        />
                        <label
                          htmlFor="terminos"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          I AGREE TO THE TERMS STATED ABOVE.
                        </label>
                      </div>

                      {message && (
                        <div className="w-full text-center">
                          <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full rounded-xl">
                            {message}
                          </p>
                        </div>
                        // <p className="mt-4 text-center bg-gradient-to-r from-[rgba(255,0,0,0.3)] to-transparent rounded-xl p-1 block w-full max-w-lg">{message}</p>
                      )}

                      <button
                        onClick={() => {
                          if (!acceptTerms) {
                            setMessage(
                              "Please accept the terms and conditions"
                            );
                          } else {
                            checkCode();
                          }
                        }}
                        id="validacontrol"
                        className="btn-primary block md:mx-auto w-full max-w-lg mt-8"
                      >
                        Validate & Vote Manually
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="text-center mt-3 mb-4">
                  <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-[#F95923]">
                      YOUR VOTE IS IMPORTANT
                    </h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      PLEASE VOTE TODAY
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      We encourage you to take advantage of Internet voting. It
                      is available 24 hours a day, 7 days a week. Internet
                      voting is available through 11:59 pm Eastern Time on
                      January 1, 2024.
                    </p>
                  </div>
                </div>
                {/* <div className="flex my-2">
                  <div className="rounded overflow-hidden w-2/5 p-3 m-2 ">
                    <p className="text-center mb-2">CUENTAS</p>
                    <p>Ticker: CUEN</p>
                    <p>CUSIP NUMBER: 229794110 </p>
                    <p>CUSIP NUMBER: 229794300</p>
                  </div>
                  
                </div> */}
                {/* <div className="flex w-full my-3">
                  <div className="rounded p-2 m-2 text-base w-1/2">
                    <p>Annual Meeting Proxy Card Number </p>
                  </div>
                  <div className="rounded p-2 m-2 w-1/2">
                    <p>Number: </p>
                  </div>
                </div> */}


                <div className="my-4">
                  <p className="text-center font-bold text-lg mb-3">
                    MANUAL VOTE FORM
                  </p>
                  <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
                  <RadioGroup
                    value={selectedfirstProposal1}
                    onChange={(value) => {
                      setFirstProposal1(value);
                      forallnom(value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 1
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      Election of John Smith, Angela Davis, Michael Johnson, Emily Brown, and Robert Miller to hold office until the 2024 Annual Meeting of Shareholders or their successors are elected and qualified.
                      </p>
                    </div>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {firstProposal1.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id}
                          value={mailingList.value}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className="mt-1 flex items-center text-sm text-gray-500"
                                  >
                                    {mailingList.description}
                                  </RadioGroup.Description>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  
                    <fieldset className="mt-4 px-32 mb-4">
                      <div className="border-t border-b border-gray-200 divide-y divide-gray-200">
                        {peopleList.map((person, personIdx) => (
                          <div
                            key={personIdx}
                            className="relative flex items-start py-4"
                          >
                            <div className="min-w-0 flex-1 text-sm">
                              <label
                                htmlFor={`person-${person.id}`}
                                className="font-medium text-gray-700 select-none"
                              >
                                {person.name}
                              </label>
                            </div>
                            <div className="ml-3 flex items-center h-5">
                              <input
                                id={`person-${person.id}`}
                                name={`person-${person.id}`}
                                type="checkbox"
                                checked={person.value}
                                disabled={disabledMembers}
                                onChange={(event) => {
                                  update(person.id, "against");
                                  peopleList[personIdx].value = event.target.value;
                                  setPeopleList([...peopleList])
                                }}
                                className="focus:ring-[#F95923] h-4 w-4 text-[#F95923] border-gray-300 rounded"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  
                  <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="mt-3 text-base font-semibold leading-7 text-[#F95923]">
                      INSTRUCTIONS
                    </h2>
                    <p className="text-md leading-8 text-gray-600">
                      To withhold authority for any individual nominee, mark{" "}
                      <span style={{ fontWeight: "bold" }}>
                        “FOR ALL EXCEPT”
                      </span>{" "}
                      and select in the square next to the nominee you wish to
                      withhold for.
                    </p>
                  </div>
                </div>
                <div className="my-2">
                  {/* <p className="text-center font-bold text-lg my-6">THE BOARD RECOMMENDS A VOTE FOR ALL OF THE FOLLOWING PROPOSALS</p> */}
                  <RadioGroup
                    value={selectedfirstProposal2}
                    onChange={(value) => {
                      setFirstProposal2(value);
                      update(6, value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 2
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      Proposal to approve executive compensation packages, ensuring our ability to attract and retain top talent while aligning executive incentives with the long-term success of the company.
                      </p>
                    </div>
                    {/* <RadioGroup.Label className="text-base font-medium text-gray-900"><strong>Proposal 2:</strong> The approval, pursuant to Nasdaq listing rules, of the issuance of the shares of common stock underlying the Inducement Warrant and the Placement Agent Warrants.</RadioGroup.Label> */}

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {votes.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id + "_2"}
                          value={mailingList.id}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <div className="my-6">
                  <RadioGroup
                    value={selectedfirstProposal3}
                    onChange={(value) => {
                      setFirstProposal3(value);
                      update(7, value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 3
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      Proposal to approve changes to the company's bylaws, ensuring they remain relevant, up-to-date and in line with best practices for corporate governance.
                      </p>
                    </div>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {votes.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id + "_3"}
                          value={mailingList.id}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <div className="my-6">
                  <RadioGroup
                    value={selectedfirstProposal4}
                    onChange={(value) => {
                      setFirstProposal4(value);
                      update(8, value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 4
                      </p>
                      <p className="mt-6 w-full text-lg leading-8 text-gray-600">
                      Proposal to approve the appointment of the external auditor, ensuring the accuracy and reliability of the company's financial reports.
                      </p>
                    </div>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {votes.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id + "_4"}
                          value={mailingList.id}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className="my-6">
                  <RadioGroup
                    value={selectedfirstProposal5}
                    onChange={(value) => {
                      setFirstProposal5(value);
                      update(9, value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 5
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      Proposal to approve investment in research and development, supporting the company's commitment to innovation and long-term growth.
                      </p>
                    </div>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {votes.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id + "_5"}
                          value={mailingList.id}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <div className="my-6">
                  <RadioGroup
                    value={selectedfirstProposal6}
                    onChange={(value) => {
                      setFirstProposal6(value);
                      update(10, value);
                    }}
                  >
                    <div className="mx-auto max-w-2xl lg:text-center">
                      <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                        PROPOSAL 6
                      </p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      Proposal to approve the strategic plan for the next five years, outlining our vision, strategic objectives, and key initiatives for future growth and success.
                      </p>
                    </div>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {votes.map((mailingList) => (
                        <RadioGroup.Option
                          key={mailingList.id + "_6"}
                          value={mailingList.id}
                          className={({ checked, active }) =>
                            classNames(
                              checked
                                ? "border-transparent"
                                : "border-gray-300",
                              active ? "ring-2 ring-[#F95923]" : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {mailingList.title}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-[#F95923]"
                                )}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-[#F95923]"
                                    : "border-transparent",
                                  "absolute -inset-px rounded-lg pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                {/* <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>THE BOARD RECOMMENDS A VOTE FOR ALL NOMINEES</th>
                        <th>MARK</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-center'>
                        <td>Proposal 1: Election of Arik Maimon, Michael De Prado, Adiv Baruch, Lexi Terrero, and Haim Yeffet to hold office until the 2024 Annual Meeting of Shareholders or their successors are elected and qualified.</td>
                        <td ></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-center'>
                        <td>FOR ALL THE NOMINEES</td>
                        <td ><input
                              id={`1-"forall"`}
                              name={`forall-1`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => forallnom("forallnom")}
                            /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-center'>
                        <td>WITHHOLD AUTHORITY FOR THE NOMINEES</td>
                        <td ><input
                              id={`1-"forall"`}
                              name={`forall-1`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => forallnom("withhol")}
                            /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-center'>
                        <td>FOR ALL EXCEPT (see instructions)</td>
                        <td ><input
                              id={`1-"forall"`}
                              name={`forall-1`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => forallnom("forallexcp")}
                            /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-right'>
                        <td>Arik Maimon</td>
                        <td ><input
                              id={`1-"for"`}
                              name={`pregunta-1`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(1, "for")}
                              style={{visibility : 'hidden'}}
                              
                            /><input
                            id={`1-"against"`}
                            name={`pregunta-1`}
                            type="radio"
                            className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                            onClick={() => update(1, "against")}
                            style={{visibility : 'hidden'}}
                          /><input
                          id={`1-"abstain"`}
                          name={`pregunta-1`}
                          type="radio"
                          className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                          onClick={() => update(1, "abstain")}
                          style={{visibility : 'hidden'}}
                        /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-right'>
                        <td>Michael De Prado</td>
                        <td ><input
                              id={`2-"for"`}
                              name={`pregunta-2`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(2, "for")}
                              style={{visibility : 'hidden'}}
                            /><input
                            id={`2-"against"`}
                            name={`pregunta-2`}
                            type="radio"
                            className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                            onClick={() => update(2, "against")}
                            style={{visibility : 'hidden'}}
                          /><input
                          id={`2-"abstain"`}
                          name={`pregunta-2`}
                          type="radio"
                          className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                          onClick={() => update(2, "abstain")}
                          style={{visibility : 'hidden'}}
                        /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-right'>
                        <td>Adiv Baruch</td>
                        <td ><input
                              id={`3-"for"`}
                              name={`pregunta-3`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(3, "for")}
                              style={{visibility : 'hidden'}}
                            /><input
                            id={`3-"against"`}
                            name={`pregunta-3`}
                            type="radio"
                            className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                            onClick={() => update(3, "against")}
                            style={{visibility : 'hidden'}}
                          /><input
                          id={`3-"abstain"`}
                          name={`pregunta-3`}
                          type="radio"
                          className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                          onClick={() => update(3, "abstain")}
                          style={{visibility : 'hidden'}}
                        /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-right'>
                        <td>Lexi Terrero</td>
                        <td ><input
                              id={`4-"for"`}
                              name={`pregunta-4`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(4, "for")}
                              style={{visibility : 'hidden'}}
                            /><input
                            id={`4-"against"`}
                            name={`pregunta-4`}
                            type="radio"
                            className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                            onClick={() => update(4, "against")}
                            style={{visibility : 'hidden'}}
                          /><input
                              id={`4-"abstain"`}
                              name={`pregunta-4`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(4, "abstain")}
                              style={{visibility : 'hidden'}}
                            /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className='text-right'>
                        <td>Haim Yeffet</td>
                        <td ><input
                              id={`5-"for"`}
                              name={`pregunta-5`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(5, "for")}
                              style={{visibility : 'hidden'}}
                            /><input
                              id={`5-"against"`}
                              name={`pregunta-5`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(5, "against")}
                              style={{visibility : 'hidden'}}
                            /><input
                              id={`5-"abstain"`}
                              name={`pregunta-5`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(5, "abstain")}
                              style={{visibility : 'hidden'}}
                            /></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={4}><b>Instructions:</b> to withhold authority for any individual nominee, mark “FOR ALL EXCEPT” and fill in with an “X” in the square next to the nominee you wish to withhold for.</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>THE BOARD RECOMMENDS TO VOTE FOR ALL OF THE FOLLOWING PROPOSALS</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>THE BOARD RECOMMENDS A VOTE FOR ALL PROPOSALS</td>
                        <td >FOR</td>
                        <td>AGAINST</td>
                        <td>ABSTAIN</td>
                      </tr>
                      <tr className='text-center'>
                        <td>Proposal 2: The approval, pursuant to Nasdaq listing rules, of the issuance of the shares of common stock underlying the Inducement Warrant and the Placement Agent Warrants.</td>
                        <td ><input
                              id={`6-"for"`}
                              name={`pregunta-6`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(6, "for")}
                            /></td>
                        <td><input
                              id={`6-"against"`}
                              name={`pregunta-6`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(6, "against")}
                            /></td>
                        <td><input
                              id={`6-"abstain"`}
                              name={`pregunta-6`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(6, "abstain")}
                            /></td>
                      </tr>
                      <tr className='text-center'>
                        <td>Proposal 3: The approval of an amendment to our Amended and Restated Articles of Incorporation, as amended, to increase the number of authorized shares of common stock from 11,076,923 to 100,000,000 shares.</td>
                        <td ><input
                              id={`7-"for"`}
                              name={`pregunta-7`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(7, "for")}
                            /></td>
                        <td><input
                              id={`7-"against"`}
                              name={`pregunta-7`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(7, "against")}
                            /></td>
                        <td><input
                              id={`7-"abstain"`}
                              name={`pregunta-7`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(7, "abstain")}
                            /></td>
                      </tr>
                      <tr className='text-center'>
                        <td>Proposal 4: The approval of the Cuentas 2023 Share Incentive Plan.</td>
                        <td ><input
                              id={`8-"for"`}
                              name={`pregunta-8`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(8, "for")}
                            /></td>
                        <td><input
                              id={`8-"against"`}
                              name={`pregunta-8`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(8, "against")}
                            /></td>
                        <td><input
                              id={`8-"abstain"`}
                              name={`pregunta-8`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(8, "abstain")}
                            /></td>
                      </tr>
                      <tr className='text-center'>
                        <td>Proposal 5: To ratify the appointment by the Board of Yarel + Partners, Certified Public Accountants (ISR.) as the Company’s independent registered public accounting firm for the fiscal year ending December 31, 2024:</td>
                        <td ><input
                              id={`9-"for"`}
                              name={`pregunta-9`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(9, "for")}
                            /></td>
                        <td><input
                              id={`9-"against"`}
                              name={`pregunta-9`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(9, "against")}
                            /></td>
                        <td><input
                              id={`9-"abstain"`}
                              name={`pregunta-9`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(9, "abstain")}
                            /></td>
                      </tr>
                      <tr className='text-center'>
                        <td>Proposal 6: The approval of a proposal to adjourn the Annual Meeting to a later date, if necessary or appropriate, to permit further solicitation and vote of proxies in the event that there are insufficient votes for, or otherwise in connection with, the approval of the Inducement Warrant Exercise Proposal, the Authorized Common Stock Proposal, or the 2023 Plan Proposal.</td>
                        <td ><input
                              id={`10-"for"`}
                              name={`pregunta-10`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(10, "for")}
                            /></td>
                        <td><input
                              id={`10-"against"`}
                              name={`pregunta-10`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(10, "against")}
                            /></td>
                        <td><input
                              id={`10-"abstain"`}
                              name={`pregunta-10`}
                              type="radio"
                              className="focus:ring-blue-900 h-4 w-4 text-blue-900 border-gray-300"
                              onClick={() => update(10, "abstain")}
                            /></td>
                      </tr>
                      
                    </tbody>
                    
                  </Table> */}
                <p className="mt-6 text-center">
                  The shares represented by this proxy, when properly executed,
                  will be voted as specified by the undersigned Stockholder(s).
                  If this card contains no specific voting instructions, the
                  shares will be voted FOR each of the Directors and proposals
                  are described on this card. In their discretion, the proxies
                  are authorized to vote upon such other business as may
                  properly come before the meeting.
                </p>
                <div className="my-6">
                  <div className="font-bold ml-4">
                    <p className="mt-6 text-center">Number: {code}</p>
                  </div>
                </div>
                {/* <Row>
                      <Col>
                          <p className='text-center'>CONTROL NUMBER</p>
                          <div className='border rounded p-2'>
                            <p>Please keep this number at hand when you exercise your vote through the Internet at https://cuentas.com/vote</p>
                          </div>
                      </Col>
                      <Col>
                        <div className='border rounded p-2 mt-2'>
                            <p>Number: </p>
                        </div>
                      </Col>
                    </Row> */}

                {message && (
                  <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full max-w-lg rounded-xl">
                    {message}
                  </p>
                  // <p className="mt-4 text-center bg-gradient-to-r from-[rgba(255,0,0,0.3)] to-transparent rounded-xl p-1 block w-full max-w-lg">{message}</p>
                )}
                <button
                  className="btn-primary mt-8 "
                  onClick={() => sendAnswers()}
                >
                  SUBMIT VOTE
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default Home;
