const End = () => {

  return (
    <main>
      <div className="flex justify-center items-start mx-4">
        <div className="card flex flex-col items-center">
          <h1 className="text-[#1B1434] text-xl text-center">
            THANKS FOR VOTING
          </h1>
          <p className="text-gray-500">Your selection has been recorded succesfully</p>
        </div>
      </div>
    </main>
  );
};

export default End;
