import { useState } from "react";
import VerificationInput from "react-verification-input";
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from "firebase/functions";

const people = [
    //{ id: 0, name: "Sara Sooy", },
    { id: 1, name: "John Smith", },
    { id: 2, name: "Angela Davis", },
    { id: 3, name: "Michael Johnson", },
    { id: 4, name: "Emily Brown", },
    { id: 5, name: "Robert Miller", },
];

const AdminCheckVote = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [code, setCode] = useState("");
    const [dataUser, setDataUser] = useState();
    
    const functions = getFunctions();
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const searchResult = () => {
        setLoading(true);
        
        if (code.length !== 9) {
            setMessage("Invalid control number");
            setLoading(false);
        } else {
            const controlNumber_searchvoted = httpsCallable(
                functions,
                "controlNumber_searchvoted"
            );
            controlNumber_searchvoted({
                documentName: "YHFX64dEVo8b8Ep52eTA",
                controlNumber: code,
            }).then((result) => {
                console.log(result)
                if (result.data.status === 200) {
                    console.log(result);
                    setMessage("");
                    setShowResult(true);
                    console.log(result.data.controlNumberdata.data)
                    let aceesDate = result.data.controlNumberdata.date_access;
                    let myDateAccess = new Date(aceesDate).toLocaleDateString('en-US', {})
                    
                    let votedDate = result.data.controlNumberdata.data_voted;
                    let myDateVoted = new Date(aceesDate).toLocaleDateString('en-US', )
                    
                    setDataUser({
                        address: result.data.controlNumberdata.address,
                        name: result.data.controlNumberdata.name,
                        shares: result.data.controlNumberdata.shares,
                        voted: result.data.controlNumberdata.voted,
                        access: result.data.controlNumberdata.access,
                        date_voted: myDateVoted,
                        date_access: myDateAccess,
                        data: result.data.controlNumberdata.data,
                    });
                } else {
                    setMessage(result.data.error);
                    setCode("Something went wrong");
                }
                setLoading(false);
            });
        }

    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

    return (
        <main>
            <div className="flex justify-center items-start mx-4">
                {loading ? (
                    <section className="flex justify-center my-12">
                        <svg
                            className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </section>
                ) : (
                    <div className="card flex flex-col items-center">
                        {showResult === false ? (
                            <>
                                <h1 className="text-[#1B1434] text-xl font-bold text-center mb-4">User Control Number</h1>

                                <form onSubmit={() => searchResult()}>
                                    <div>
                                        <VerificationInput
                                            classNames={{
                                                character:
                                                    "form-control h-8 md:h-full shadow-md shadow-[#e4e2f5] background-color[#f6f5fa]",
                                                container: "w-auto",
                                            }}
                                            placeholder="&#160;&#160;"
                                            inputProps={{ type: "tel" }}
                                            length={9}
                                            onChange={(e) => setCode(e)}
                                        />
                                    </div>

                                    {message && (
                                        <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full max-w-lg rounded-xl">
                                            {message}
                                        </p>
                                    )}

                                    <button
                                        className="btn-primary block md:mx-auto w-full max-w-lg mt-8"
                                        onClick={() => {
                                            searchResult();
                                          }}                                        
                                    >
                                        Lookup User
                                    </button>
                                </form>
                            </>
                        ) : (
                            <div>
                                <ul role="list" className="divide-y divide-gray-100">
                                    <li className="flex justify-between gap-x-32 py-1">
                                        <div className="flex min-w-0 gap-x-6">
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-base font-bold leading-6 text-gray-900">{dataUser.name}</p>
                                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{dataUser.address}</p>
                                                {
                                                    dataUser.voted && (
                                                        <>
                                                            <p className="mt-1 truncate text-xs leading-5 text-gray-500"><span className="font-bold">Date of Access:</span> {dataUser.date_access}</p>
                                                            <p className="mt-1 truncate text-xs leading-5 text-gray-500"><span className="font-bold">Date of Vote:</span> {dataUser.date_voted}</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                            <p className="text-sm leading-6 text-gray-900">{dataUser.shares} Shares</p>
                                            <div className="mt-1 flex items-center gap-x-1.5">
                                                {
                                                    dataUser.voted ? (
                                                        <>
                                                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500"></div>
                                                            </div>
                                                            <p className="text-xs leading-5 text-gray-500">
                                                                {
                                                                    dataUser.manual_vote ? (
                                                                        "Manual Vote"
                                                                    ) : ( "Voted" )
                                                                }
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="flex-none rounded-full bg-gray-500/20 p-1">
                                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-500"></div>
                                                            </div>
                                                            <p className="text-xs leading-5 text-gray-500">No Vote</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        dataUser.voted && dataUser.data.length > 0 && (
                                            <>
                                                <li className="flex justify-between gap-x-20 py-2 mt-8">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900 mb-1">Proposal 1</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900 py-2"></p>
                                                    </div>
                                                </li>
                                                {
                                                                people.map((person, i) => {
                                                                    
                                                                    return (
                                                                        
                                                                        <li key={person+'_'+i} className="flex justify-between gap-x-20 pl-5 py-1 ">
                                                                            <div className="flex min-w-0 gap-x-4">
                                                                                <div className="min-w-0 flex-auto">
                                                                                    <p className="text-sm py-2">{person.name}</p> 
                                                                                </div>
                                                                            </div>
                                                                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                                                <p key={'Vote_' + i} className="text-sm py-1 leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[person.id])}</p>
                                                                            </div>
                                                                        </li>
                                                                        
                                                                    )
                                                                    
                                                                })
                                                            }
                                                
                                                <li className="flex justify-between gap-x-20 py-2">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900">Proposal 2</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[6])}</p>
                                                    </div>
                                                </li>
                                                <li className="flex justify-between gap-x-20 py-2">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900">Proposal 3</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[7])}</p>
                                                    </div>
                                                </li>
                                                <li className="flex justify-between gap-x-20 py-2">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900">Proposal 4</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[8])}</p>
                                                    </div>
                                                </li>
                                                <li className="flex justify-between gap-x-20 py-2">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900">Proposal 5</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[9])}</p>
                                                    </div>
                                                </li>
                                                <li className="flex justify-between gap-x-20 py-2">
                                                    <div className="flex min-w-0 gap-x-4">
                                                        <div className="min-w-0 flex-auto">
                                                            <p className="text-sm font-semibold leading-6 text-gray-900">Proposal 6</p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                        <p className="text-sm leading-6 text-gray-900">{capitalizeFirstLetter(dataUser.data[10])}</p>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }
                                    <div className="flex space-x-4">
                                        <button
                                            id="recoverCode"
                                            className="btn-primary block md:mx-auto w-full max-w-lg mt-8"
                                            onClick={() => {
                                                setShowResult(false);
                                                setDataUser({});
                                            }}
                                        >
                                            Return
                                        </button>
                                    </div>

                                </ul>

                            </div>
                        )}
                    </div>
                )}
            </div>
        </main>
    )
}

export default AdminCheckVote;