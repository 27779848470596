import { useState } from "react";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import VerificationInput from "react-verification-input";
import { useNavigate } from "react-router-dom";

const Preguntas = [
  "op no valida",
  "John Smith",
  "Angela Davis",
  "Michael Johnson",
  "Emily Brown",
  "Robert Miller",
  "Proposal to approve executive compensation packages, ensuring our ability to attract and retain top talent while aligning executive incentives with the long-term success of the company.",
  "Proposal to approve changes to the company's bylaws, ensuring they remain relevant, up-to-date and in line with best practices for corporate governance.",
  "Proposal to approve the appointment of the external auditor, ensuring the accuracy and reliability of the company's financial reports.",
  "Proposal to approve investment in research and development, supporting the company's commitment to innovation and long-term growth.",
  "Proposal to approve the strategic plan for the next five years, outlining our vision, strategic objectives, and key initiatives for future growth and success.",
];

const Admin = () => {
  const functions = getFunctions();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [questionsData, setQuestionsData] = useState("");
  const [vote_counter, setVote_counter] = useState(0);
  const [vote_access, setAccess] = useState(0);
  const [vote_voted, setVoted] = useState(0);
  const [target, setTarget] = useState(0);
  const [campaignData, setCampaignData] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [quorum, setQuorum] = useState("");
  const [votedData, setVotedData] = useState("");
  const [filenamev, setFilenamev] = useState("");
  const [filenameq, setFilenameq] = useState("");
  //linea para conectar el emulador
  //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  const navigate = useNavigate();
  const expordata = () => {
    const controlExport_data = httpsCallable(functions, "controlExport_data");
    controlExport_data({
      documentName: "YHFX64dEVo8b8Ep52eTA",
    }).then((result) => {
      if (result.data.status === 200) {
        alert("The platform reports are ready to be downloaded");
        console.log(result);
        setFilenamev(result.data.pathReferencev);
        setFilenameq(result.data.pathReferenceq);
      } else {
        setMessage(result.data.error);
        setCode("Something went wrong");
      }
    });
  };
  const valquorum = () => {
    console.log("valquorum", target / 2, vote_counter);
    if (target / 2 > vote_counter) setQuorum("NO");
    else setQuorum("SI");
  };

  const searchResult = () => {
    setLoading(true);

    if (password.length !== 9) {
      setMessage("Invalid password");
      setLoading(false);
    } else {
      const campaign_get_results = httpsCallable(
        functions,
        "campaign_get_results"
      );
      campaign_get_results({
        documentName: "YHFX64dEVo8b8Ep52eTA",
        password: password,
      }).then((result) => {
        console.log(result);

        if (result.data.status === 200) {
          setMessage("");
          setShowResult(true);
          console.log("then", result);
          setVote_counter(
            parseInt(result.data.data.questionsData.vote_counter_shares) +
              parseInt(result.data.data.questionsData.vote_access_shares)
          );
          setTarget(result.data.data.campaignData.shares);
          setVoted(result.data.data.questionsData.vote_counter);
          setAccess(result.data.data.questionsData.vote_access);
          const questions_data = Object.values(result.data.data.questionsData);
          setQuestionsData(questions_data);

          valquorum(
            result.data.data.campaignData.shares,
            parseInt(result.data.data.questionsData.vote_counter_shares) +
              parseInt(result.data.data.questionsData.vote_access_shares)
          );
        } else {
          setMessage(result.data.error);
        }
        setLoading(false);
      });
    }
  };

  return (
    <main>
      <div className="flex justify-center items-start mx-4">
        {loading ? (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        ) : (
          <div className="card flex flex-col items-center">
            {showResult === false ? (
              <>
                <h1 className="text-[#1B1434] text-xl text-center mb-4">
                  Dashboard Access
                </h1>

                <form onSubmit={() => searchResult()}>
                  <div>
                    <VerificationInput
                      classNames={{
                        character:
                          "form-control h-8 md:h-full shadow-md shadow-[#e4e2f5] background-color[#f6f5fa]",
                        container: "w-auto",
                      }}
                      placeholder="&#160;&#160;"
                      length={9}
                      onChange={(e) => setPassword(e)}
                    />
                  </div>

                  {message && (
                    <p className="mt-4 text-center text-red-600 bg-red-100 p-1 block w-full rounded-xl">
                      {message}
                    </p>
                  )}

                  <button
                    className="btn-primary block md:mx-auto w-full mt-8"
                    type="submit"
                  >
                    Validate & Access
                  </button>
                </form>
              </>
            ) : (
              <>
                <div className="bg-gray-50 pt-12 sm:pt-16">
                  <div className=" mx-auto px-4 sm:px-6 lg:px-8">
                    <div className=" mx-auto text-center">
                      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                      X-Trategy, Inc.
                      </h1>
                      <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                      Dashboard
                      </h2>
                      <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                        Visualize the metrics and export the results
                      </p>
                    </div>
                  </div>

                  <div className="mt-10 pb-10 bg-white sm:pb-12">
                    <div className="relative">
                      <div className="absolute inset-0 h-1/2 bg-gray-50" />
                      <div className="relative  mx-auto px-4 sm:px-6 lg:px-8">
                        <div className=" mx-auto">
                          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                Voted Shares ({(vote_voted + vote_access)} / votes)
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                                {(vote_counter).toLocaleString("en-US")}
                              </dd>
                            </div>
                            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                Expected Shares
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                                {(target).toLocaleString("en-US")}
                              </dd>
                            </div>
                            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                Participation
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                               {((vote_counter * 100) / target).toFixed(1)}%
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 pb-10 bg-white sm:pb-12">
                    <div className="relative">
                      <div className="absolute inset-0 h-1/2 bg-gray-50" />
                      <div className="relative  mx-auto px-4 sm:px-6 lg:px-8">
                        <div className=" mx-auto">
                          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                Minimum Quorum
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                                {(target / 2 + 1).toLocaleString("en-US")}
                              </dd>
                            </div>
                            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                Total Quorum
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                                {(target).toLocaleString("en-US")}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 pb-10 bg-white sm:pb-12">
                    <div className="relative">
                      <div className="absolute inset-0 h-1/2 bg-gray-50" />
                      <div className="relative  mx-auto px-4 sm:px-6 lg:px-8">
                        <div className=" mx-auto">
                          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                              Shareholders Accessed
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                              {(vote_access).toLocaleString("en-US")}
                              </dd>
                            </div>
                            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                              Shareholders Voted
                              </dt>
                              <dd className="order-1 text-5xl font-extrabold text-[#F95923]">
                              {(vote_voted).toLocaleString("en-US")}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="py-4 border-b border-b-gray-400 w-full text-center">
                  <label className="font-medium text-gray-900">
                    PROPOSAL NO. {index + 1}
                  </label>
                  <ul className="flex flex-col md:flex-row justify-center items-center space-x-4">
                    <li>For {res.for ? res.for : 0}</li>
                    <li>Against {res.against ? res.against : 0}</li>
                    <li>Abstain {res.abstain ? res.abstain : 0}</li>
                  </ul>
                </div> */}
                {questionsData.map((res, index) => (
                  <div key={index} className="w-full">
                    {index > 0 && index <= 10 && (
                      <>
                        {index === 1 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 1
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Election of John Smith, Angela Davis, Michael Johnson, Emily Brown, and Robert Miller to hold office until the 2024 Annual Meeting of Shareholders or their successors are elected and qualified.
                            </p>
                          </div>
                        )}

                        {index === 6 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 2
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Proposal to approve executive compensation packages, ensuring our ability to attract and retain top talent while aligning executive incentives with the long-term success of the company.
                            </p>
                          </div>
                        )}
                        {index === 7 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 3
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Proposal to approve changes to the company's bylaws, ensuring they remain relevant, up-to-date and in line with best practices for corporate governance.
                            </p>
                          </div>
                        )}
                        {index === 8 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 4
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Proposal to approve the appointment of the external auditor, ensuring the accuracy and reliability of the company's financial reports.
                            </p>
                          </div>
                        )}
                        {index === 9 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 5
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Proposal to approve investment in research and development, supporting the company's commitment to innovation and long-term growth.
                            </p>
                          </div>
                        )}
                        {index === 10 && (
                          <div className="mx-auto  mt-10 mb-4 lg:text-center">
                            <p className="mt-2 text-2xl font-bold tracking-tight  text-[#313131] sm:text-2xl">
                              PROPOSAL 6
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Proposal to approve the strategic plan for the next five years, outlining our vision, strategic objectives, and key initiatives for future growth and success.
                            </p>
                          </div>
                        )}
                        {/* <h3 className="text-center text-lg leading-6 text-[#1b1434] my-4">
                          {Preguntas[index]}
                        </h3> */}
                        
                        {index === 1 && (
                          <div className="mx-auto lg:text-center">
                            <p className="mt-10 text-lg font-bold leading-8 text-gray-600">
                            {Preguntas[index]}
                            </p>
                          </div>
                        )}
                        {index === 2 && (
                          <div className="mx-auto lg:text-center">
                          <p className="mt-10 text-lg font-bold leading-8 text-gray-600">
                          {Preguntas[index]}
                          </p>
                        </div>
                        )}
                        {index === 3 && (
                          <div className="mx-auto lg:text-center">
                          <p className="mt-10 text-lg font-bold leading-8 text-gray-600">
                          {Preguntas[index]}
                          </p>
                        </div>
                        )}
                        {index === 4 && (
                          <div className="mx-auto lg:text-center">
                          <p className="mt-10 text-lg font-bold leading-8 text-gray-600">
                          {Preguntas[index]}
                          </p>
                        </div>
                        )}
                        {index === 5 && (
                          <div className="mx-auto lg:text-center">
                          <p className="mt-10 text-lg font-bold leading-8 text-gray-600">
                          {Preguntas[index]}
                          </p>
                        </div>
                        )}
                        <dl className="grid grid-cols-4 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x">
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-base text-gray-900">For</dt>
                            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                              <div className="flex items-baseline text-2xl font-semibold text-[#F95923]">
                                {res.for
                                  ? ((res.for * 100) / vote_counter).toFixed(1)
                                  : 0}
                                %
                                <span className="ml-2 text-sm font-medium text-gray-500">
                                  {res.for ? res.for : 0} shares
                                </span>
                              </div>
                            </dd>
                          </div>
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-base text-gray-900">Against</dt>
                            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                              <div className="flex items-baseline text-2xl font-semibold text-[#F95923]">
                                {res.against
                                  ? (
                                      (res.against * 100) /
                                      vote_counter
                                    ).toFixed(1)
                                  : 0}
                                %
                                <span className="ml-2 text-sm font-medium text-gray-500">
                                  {res.against ? res.against : 0} shares
                                </span>
                              </div>
                            </dd>
                          </div>


                          {index > 0 && (
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-base text-gray-900">Abstain</dt>
                            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                              <div className="flex items-baseline text-2xl font-semibold text-[#F95923]">
                                {res.abstain
                                  ? (
                                      (res.abstain * 100) /
                                      vote_counter
                                    ).toFixed(1)
                                  : 0}
                                %
                                <span className="ml-2 text-sm font-medium text-gray-500">
                                  {res.abstain ? res.abstain : 0} shares
                                </span>
                              </div>
                            </dd>
                          </div>
                          )}


                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-base text-gray-900">Free</dt>
                            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                              <div className="flex items-baseline text-2xl font-semibold text-[#F95923]">
                                {res.free
                                  ? ((res.free * 100) / vote_counter).toFixed(1)
                                  : 0}
                                %
                                <span className="ml-2 text-sm font-medium text-gray-500">
                                  {res.free ? res.free : 0} shares
                                </span>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </>
                    )}
                  </div>
                ))}

                <button
                  className="btn-primary block md:mx-auto w-full  mt-8"
                  onClick={() => {
                    expordata();
                    this.disabled = true;
                  }}
                >
                  Generate Reports
                </button>
                {filenamev && (
                  <a href={filenamev} download>
                    {" "}
                    Download Users Report{" "}
                  </a>
                )}
                {filenameq && (
                  <a href={filenameq} download>
                    {" "}
                    Download Proposals Report{" "}
                  </a>
                )}
                <button
                  className="btn-primary block md:mx-auto w-full  mt-8"
                  onClick={() => {
                    navigate("/admincheckvote");
                  }}
                >
                  Inspect User
                </button>
                <button
                  className="btn-primary block md:mx-auto w-full  mt-8"
                  onClick={() => {
                    navigate("/adminvote");
                  }}
                >
                  Insert Manual Vote
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default Admin;
